  <template>
  <div class="ed-experience">
    <el-alert
      type="warning"
      :closable="false"
      description="证明资料仅限PDF文档(资料包括毕业证书)"
      show-icon>
    </el-alert>
    <div class="form sctp-bg-white pad15">
      <el-form :model="form" status-icon :rules="rules" ref="rules"
               class="demo-ruleForm" size="mini">
        <el-row v-for="(experiences, index) in form.experiences" :key="index" class="sctp-flex">
          <el-form-item>
            {{ index + 1 }}、
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.schoolname'" :rules="rules.schoolname">
            <el-input v-model="experiences.schoolname" placeholder="学校名称"></el-input>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.starttime'" :rules="rules.starttime">
            <el-date-picker type="date" v-model="experiences.starttime"
                            placeholder="开始时间" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          ~
          <el-form-item :prop="'experiences.' + index + '.endtime'" :rules="rules.endtime">
            <el-date-picker type="date" v-model="experiences.endtime"
                            placeholder="结束时间" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.major'" :rules="rules.major">
            <el-input v-model="experiences.major" placeholder="专业"></el-input>
          </el-form-item>
          <el-form-item :prop="'experiences.' + index + '.education'" :rules="rules.education">
            <el-select v-model="experiences.education" placeholder="学历">
              <el-option
                v-for="item in educationOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-upload
              :http-request="fileUploadFunction"
              :limit="2"
              :data="{...uploadEnum.JOB}"
              :on-exceed="fileCountOver"
              action=""
              :before-upload="beforeUpload"
              class="file-inline"
              accept=".PDF"
              :file-list="experiences.provesList"
              :on-success="uploadSuccessPositive.bind(null,{index:index})"
              :on-remove="handleRemove.bind(null,{index:index})">
              <el-button size="small" icon="el-icon-link">上传证明材料</el-button>
              <div class="el-upload__tip" slot="tip">
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" class="reduce-item" circle
                       @click="deleteLine(index)"></el-button>
          </el-form-item>
        </el-row>
        <el-row class="sctp-flex pad15">
          <el-form-item>
            <el-button type="primary" @click="submitForm()">保存</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="form.experiences.push({ userid: null, starttime: null, endtime: null, schoolname: null, major: null, education: null })">
              <i class="el-icon-plus"></i>增加
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
const config = require('../../../resources/js/config')
import uploadEnum from "@/resources/js/uploadenum";

export default {
  name: 'edExperience',
  data() {
    return {
      config,
      uploadEnum,
      educationOptions: ['初中', '高中', '大专', '本科', '硕士', '博士'],
      form: {
        experiences: [{
          userid: null,
          starttime: null,
          endtime: null,
          schoolname: null,
          major: null,
          education: null,
          id: null,
          proves: null,
          provesList: []
        }]
      },
      rules: {
        starttime: [{required: true, message: '请输入开始时间'},
          {
            validator: (rule, value, callback) => {
              var index = rule.field.indexOf('.')
              let ind = parseInt(rule.field.substr(index+1,1))
              let va1 = new Date(this.form.experiences[ind].endtime)
              let va2 = new Date(value)
              if (va1 < va2 && va1.getTime() != new Date('1970-01-01').getTime()) {
                this.form.experiences[ind].starttime = ""
                this.$message.error('工作开始时间不能大于工作结束时间')
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          }
        ],
        endtime: [{required: true, message: '请输入结束时间'},
          {
            validator: (rule, value, callback) => {
              var index = rule.field.indexOf('.')
              let ind = parseInt(rule.field.substr(index+1,1))
              let va1 = new Date(this.form.experiences[ind].starttime)
              let va2 = new Date(value)
              if (va1.getTime() > va2.getTime()) {
                this.form.experiences[ind].endtime = ""
                this.$message.error('工作结束时间不能小于工作开始时间')
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          }],
        schoolname: [{required: true, message: '请输入学校名称'},
          {
            validator: (rule, value, callback) => {
              const va = /^[\u4E00-\u9FA5A-Za-z_]+$/;
              if (!va.test(value)) {
                callback('学校名称不能包含特殊符号或数字 ');
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          }],
        major: [{required: true, message: '请输入专业'},
          {
            validator: (rule, value, callback) => {
              const va = /^[\u4E00-\u9FA5A-Za-z_]+$/;
              if (!va.test(value)) {
                callback('专业不能包含特殊符号');
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          },

        ],
        education: [{required: true, message: '请选择学历'}]
      }
    }
  },
  methods: {
    uploadSuccessPositive({index}, res, file, fileList) {
      if (res.code) {
        const {src} = res
        // if (this.form.experiences[index].proves != null) {
        //   this.form.experiences[index].proves += ',' + src
        // } else {
        //   this.form.experiences[index].proves = src
        // }
        this.form.experiences[index].proves = fileList.map(
          item => item.path || item.response.src).join(',');
        this.$message.success('上传成功')
      } else {
        this.$message.error('上传文件失败')
      }
    },
    uploadFileError() {
      this.$message.error('文件上传失败');
    },
    beforeUpload(file) {
      const isImage = file.type;
      const isLtSize = file.size / 1024 / 1024 < 5;
      if (!isLtSize) {
        this.$message.error('图片大小不能超过5MB!');
      }
      return isImage && isLtSize && this.$message('文件正在上传') && true;
    }, handleRemove({index}, file, fileList) {
      this.form.experiences[index].proves = fileList.map(
        item => item.path || item.response.src).join(',');
    }, fileCountOver() {
      this.$message.warning('文件数量已达最大限制')
    },
    async submitForm() {
      if (await this.$refs.rules.validate()) {
        this.form.experiences.forEach(experience => experience.userid = this.user.userId)
        this.$request.post({
          reqcode: '1116',
          reqdesc: '覆盖更新教育经历',
          data: this.form.experiences
        }).then(res => {
          this.$message.success('保存成功')
          this.queryList()
        })
      } else {
        this.$message.warning('表单填写错误')
      }
    },
    deleteLine(index) {
      const id = this.form.experiences[index].id
      if (id != null) {
        this.$confirm('确定要删除嘛?', '提示', {})
        .then(() => {
          this.$request.post({
            reqcode: '1123',
            reqdesc: '删除个人履历信息',
            id: id,
            type: 2
          }).then(res => {
            this.$message.success(res.retDesc)
            this.form.experiences.splice(index, 1)
          })
        })
      } else {
        this.form.experiences.splice(index, 1)
      }
    },
    queryList() {
      this.$request.post({
        reqcode: '1115',
        reqdesc: '查询教育经历',
        userid: this.user.userId
      }).then(res => {
        let {retdata} = res;
        retdata.forEach(work => {
          work.starttime = this.dateToString(new Date(work.starttime), 'yyyy-MM-dd')
          work.endtime = this.dateToString(new Date(work.endtime), 'yyyy-MM-dd')
          if (work.proves) {
            work.provesList = work.proves.split(',').map(item => {
              return {
                url: item,
                path: item.substring(item.indexOf('/upload/')),
                name: item.substring(item.lastIndexOf('/') + 1)
              }
            })
          }
        })
        if (retdata && retdata.length > 0) {
          this.form.experiences = retdata;
        }
      })
    }
  },
  mounted() {
    this.queryList()
  }
}
</script>

<style scoped lang="scss">
.ed-experience {
  ::v-deep {
    .file-inline {
      display: flex;
      align-items: center;

      .el-upload-list {
        display: flex;
      }

      .el-upload-list__item {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        .el-upload-list__item-name {
          text-overflow: clip;
          width: 20px;
          margin-right: 0;
          padding-left: 0;
          line-height: 1;
        }

        .el-icon-close {
          display: block;
          position: relative;
          top: 0;
          right: 0;
          opacity: 1;
        }

        .el-upload-list__item-status-label {
          display: none;
        }

      }
    }
  }
}

.el-form-item {
  margin: 0 5px;
}

.add-item, .reduce-item {
  background: url(/images/user/experience/add-reduce.jpg) no-repeat;
  background-size: 63px;
  border: 0;
  padding: 0;
  width: 30px;
  height: 38px;
}

.reduce-item {
  background-position: -31px 0;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 150px;
}

.el-icon-info {
  font-size: 16px;
  width: 16px;
  color: #909399;
}

</style>
